import { theme } from '@theme';
import styled from 'styled-components';

interface SectionContactProps {
    sectionColor: string;
}

const { shadow, border, color, typography, zIndex, breakpoints } = theme;

const maxCotent = (max: number) => `
    max-width: ${max}px;
    width: 100%;
    margin: 0 auto;
    `;

export const Main = styled.div``;

export const MainSlider = styled.div`
    ${maxCotent(879)};
`;

export const BoxSection = styled.div`

    ${breakpoints.up('md', `
        padding: 120px 0;
    `)}
`;

export const SectionContact = styled.div<SectionContactProps>`
    position: relative;
    background-color: ${color.common.white};

    &::before {
        content: '';
        display: block;
        height: 50%;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: ${({ sectionColor }) => sectionColor};
    }
`;

export const FeaturedBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    &.mr-b {
        // margin-bottom: 328px;
        
        ${breakpoints.down('md', `
            // margin-bottom: 30px;
        `)}

    }

    &.left {
        flex-direction: row-reverse;
        ${breakpoints.down('md', `
            align-items: stretch;
            
            & > .column.info {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
            
            & > .column.image {
                & > .WrapAnchorPicture {
                    
                    & > .AnchorPicture {
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                    }
                }
            }
        `)}

        
    }
    
    &.right {

        // flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;

        & > .column.image {
            left: 0;
            right: auto;
        }

        ${breakpoints.down('md', `
            align-items: stretch;
            
            & > .column.info {
                border-radius: ${border.default};
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
            
            & > .column.image {
                & > .WrapAnchorPicture {
                    
                    & > .AnchorPicture {
                    border-radius: ${border.default};
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                    }
                }
            }
        `)}

    }

    &.left,
    &.right {
        ${breakpoints.down('md', `
            flex-direction: column-reverse;
            align-items: stretch;
            
            & > .column.info {
                border-radius: ${border.default};
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
            
            & > .column.image {
                & > .WrapAnchorPicture {
                    
                    & > .AnchorPicture {
                    border-radius: ${border.default};
                        border-bottom-left-radius: 0;
                        border-bottom-right-radius: 0;
                    }
                }
            }
        `)}
    }
`;

export const ColumnBox = styled.div`
    &.column.info {
        position: absolute;
        left: 0;
        border: .0625rem solid ${color.grey[50]};
        box-shadow: ${shadow.default};
        background-color: ${color.common.white};
        padding: 54px 70px;
        border-radius: ${border.default};
        max-width: 576px;
        width: 100%;
        z-index: ${zIndex.min};

        &.right {
            left: auto;
            right: 0;
        }

        ${breakpoints.down('lg', `
        `)}

        ${breakpoints.down('md', `
            position: relative;
            max-width: none;
            padding: 30px 30px;
        `)}
    }
    

    &.column.image {
        position: relative;
        max-width: 778px;
        width: 100%;
        
        ${breakpoints.down('md', `
            position: relative;
            width: auto;
        `)}

       & > .WrapAnchorPicture {
            height: 100vh;
            max-height: 448px;
            
            ${breakpoints.down('md', `
                max-height: 258px;
            `)}
        
            & > .AnchorPicture {
                display: block;
                box-shadow: ${shadow.default};
                height: 100%;
                width: 100%;
                overflow: hidden;
                border-radius: ${border.default};
                
                & > .WrapPicture {
                    height: 100%;
                    width: 100%;
                    picture {
                        source,
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border: 0;
                            max-width: 100%;
                            vertical-align: middle;
                        }
                    }
                }
            }
        }
    }
`;

interface BoxTextProps {
    color: string;
}

export const BoxTitle = styled.h1<BoxTextProps>`
    font-size: 1.75rem;
    color: ${props => props.color};
    font-weight: ${typography.fontWeightBold};
    text-align: left;
    margin-bottom: .9375rem;
    
    ${breakpoints.down('md', `
        font-size: 1.375rem;
    `)}
`;

export const BoxText = styled.p<BoxTextProps>`
    font-size: 1rem;
    font-weight: ${typography.fontWeightRegular};
    color: ${props => props.color};
    margin-bottom: 1.875rem;

    ${breakpoints.down('md', `
        font-size: .875rem;
    `)}
`;

export const PartnerWarning = styled.div`
    text-align: center;
    display: flex;
    justify-content: center;
    color: ${theme.color.grey[700]};
    height: 85px;
`;

export const FaqSpaceBottom = styled.section`
  padding-bottom: 120px;  
  ${breakpoints.down('md', `
    padding-bottom: 6.875rem;
  `)}
`;

export const ContactFormWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

export const FaqContainer = styled.div`
    display: flex;
    justify-content: center;
`;

export const FaqWrapper = styled.div`
    max-width: 980px;
    width: 100%;
`;
